.link-lead {
  cursor: pointer;
}

.lead-novo{
  position: relative;
  background-color: var(--blue-50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  overflow: hidden;

  .dragg{
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--cinza-azulado-500);
    cursor:pointer;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .status{
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--azul-happy);
    color:var(--white);
    cursor:pointer;
    width: fit-content;
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-bottom: 5px !important;
  }

  p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-400);
  }

  .line-lead{
    border: 2px solid var(--azul-happy);
    width: 120%;
    margin: 12px -16px 12px -16px;
  }

  .icons{
    padding-right: 0px;

    .starts{
      display: flex;
      gap: 5px;
      margin-right: 10px;
    }

    .box-icones{
      display: flex;
      gap: 12px;
      position: relative;

      .dot-red{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--Red_A400);
        position: absolute;
        top:1px;
        left: 24px;
      }
    }
  }
}

.lead-atrasado{
  position: relative;
  background-color: var(--vermelho-50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  overflow: hidden;

  .dragg{
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--cinza-azulado-500);
    cursor:pointer;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .status{
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--vermelho-atrasado);
    color:var(--white);
    cursor:pointer;
    width: fit-content;
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-bottom: 5px !important;
  }

  p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-400);
  }

  .line-lead{
    border: 2px solid var(--vermelho-atrasado);
    width: 120%;
    margin: 12px -16px 12px -16px;
  }

  .icons{
    padding-right: 0px;

    .starts{
      display: flex;
      gap: 5px;
      margin-right: 10px;
    }

    .box-icones{
      display: flex;
      gap: 12px;
      position: relative;

      .dot-red{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--Red_A400);
        position: absolute;
        top:1px;
        left: 24px;
      }
    }
  }
}

.lead-hoje{
  position: relative;
  background-color: var(--amarelo-50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  overflow: hidden;

  .dragg{
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--cinza-azulado-500);
    cursor:pointer;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .status{
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--amarelo-hoje);
    color:var(--white);
    cursor:pointer;
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-bottom: 5px !important;
    width: fit-content;
  }

  p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-400);
  }

  .line-lead{
    border: 2px solid var(--amarelo-hoje);
    width: 120%;
    margin: 12px -16px 12px -16px;
  }

  .icons{
    padding-right: 0px;

    .starts{
      display: flex;
      gap: 5px;
      margin-right: 10px;
    }

    .box-icones{
      display: flex;
      gap: 12px;
      position: relative;

      .dot-red{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--Red_A400);
        position: absolute;
        top:1px;
        left: 24px;
      }
    }
  }
}

.lead-futuro{
  position: relative;
  background-color: var(--verde-50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  overflow: hidden;

  .dragg{
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--cinza-azulado-500);
    cursor:pointer;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .status{
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--verde-futuro);
    color:var(--white);
    cursor:pointer;
    width: fit-content;
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-bottom: 5px !important;
  }

  p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-400);
  }

  .line-lead{
    border: 2px solid var(--verde-futuro);
    width: 120%;
    margin: 12px -16px 12px -16px;
  }

  .icons{
    padding-right: 0px;

    .starts{
      display: flex;
      gap: 5px;
      margin-right: 10px;
    }

    .box-icones{
      display: flex;
      gap: 12px;
      position: relative;

      .dot-red{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--Red_A400);
        position: absolute;
        top:1px;
        left: 24px;
      }
    }
  }
}

.modal-info{
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%,0%);
  width: auto;
  height: auto;
  border-radius: 8px 8px 0px 0px;
  background: var(--white);
  padding: 25px;
  max-width: 850px;
  transition: 200ms;
  border-bottom: 4px solid var(--azul-happy);

  .button-close{
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: var(--cinza-azulado-500);
  }

  h3{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 20px !important;
  }

  h4{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: var(--cinza-azulado-300);
    margin-bottom: 15px !important;
  }

  h5{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #607D8B;
    margin-bottom: 20px !important;
  }

  h5#title-assunto{
    margin-bottom: 5px !important;
  }

  h6{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #607D8B;
    margin-bottom: 20px !important;
  }

  textarea{
    border-radius: 8px;
    border: 1px solid var(--cinza-azulado-400);
    padding: 10px;
    width:100%;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #6C757D;
  }

  p{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: var(--cinza-azulado-700);
    margin-bottom: 10px !important;
  }

  .box-note{
    border-radius: 8px;
    border: 2px solid var(--cinza-azulado-400);
    padding: 15px;

    p{
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #607D8B;
    }
  }
}

