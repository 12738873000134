.tag-check{
  background-color: var(--azul-secundario);
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;

  p{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color:var(--cinza-azulado-400);
  }
}

.tag-novo{
  background-color: var(--azul-secundario);
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;

  p{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color:var(--azul-happy);
  }

  span{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px;
    color:var(--azul-happy) !important;
  }
}

.tag-atrasado{
  background-color: var(--vermelho-50);
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;

  p{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color:var(--vermelho-atrasado);
  }

  span{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px;
    color:var(--vermelho-atrasado) !important;
  }
}

.tag-hoje{
  background-color: var(--amarelo-50);
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;

  p{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color:var(--amarelo-hoje);
  }

  span{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px;
    color:var(--amarelo-hoje) !important;
  }
}

.tag-futuro{
  background-color: var(--verde-50);
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;

  p{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color:var(--verde-futuro);
  }

  span{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px;
    color:var(--verde-futuro) !important;
  }
}

.tag-tarefaConcluida{
  background-color: var(--check-filtro-tarefa-concluido);
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;

  p{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color:var(--check-filtro-tarefa-concluido-text);
  }

  span{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px;
    color:var(--verde-futuro) !important;
  }
}

.check-novo{
  .ant-checkbox-checked::after{
    border: 1px solid var(--azul-happy) !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--azul-happy) !important;
    border-color: var(--azul-happy) !important;
  }
}

.check-atrasado{
  .ant-checkbox-checked::after{
    border: 1px solid var(--vermelho-atrasado) !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--vermelho-atrasado) !important;
    border-color: var(--vermelho-atrasado) !important;
  }
}

.check-hoje{
  .ant-checkbox-checked::after{
    border: 1px solid var(--amarelo-hoje) !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--amarelo-hoje) !important;
    border-color: var(--amarelo-hoje) !important;
  }
}

.check-futuro{
  .ant-checkbox-checked::after{
    border: 1px solid var(--verde-futuro) !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: var(--verde-futuro) !important;
    border-color: var(--verde-futuro) !important;
  }
}

.ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner{
  border: 0px solid transparent !important;
}

