.avatar {
	margin: 30px 10px 20px 10px;
	width: 50px;
	height: 50px;
	border-radius: 45px;
}

.user-default {
	margin: 30px 10px 20px 10px;
	width: 50px;
	height: 50px;
	background-color: #cbd4ed;
	border-radius: 45px;
}
