.area-table{
  padding: 0px !important;
  background-color: var(--white);
}

.table-list{
  max-width: calc(100vw - 141px) !important;
}

.area-list{
  padding-right: 0px !important;
}

.header-list{
  padding: 10px 30px;
  height: 80px;
  background: var(--azul-happy);
  transition: opacity 0.5s;
  opacity: 1;

  p{
    color:var(--white);
  }
}
