@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
	--primary-blue-base: #1a4da1;
	--secondary-dark-blue: #92a9cb;
	--primary-gray-base: #344453;
	--primary-silver-dark: #cbd4ed;
	--azul-happy: #0c306c;
	--azul-secundario: #ebeffc;
	--cinza-azulado: #90a4ae;
	--cinza-azulado-50: #eceff1;
	--cinza-azulado-100: #cfd8dc;
	--cinza-azulado-200: #b0bec5;
	--cinza-azulado-300: #90a4ae;
	--cinza-azulado-400: #78909c;
	--cinza-azulado-500: #607d8b;
	--cinza-azulado-600: #546e7a;
	--cinza-azulado-700: #455a64;
	--cinza-azulado-800: #37474f;
	--cinza-azulado-900: #263238;
	--vermelho-atrasado: #f44336;
	--vermelho-50: #ffebee;
	--amarelo-hoje: #ffa000;
	--amarelo-50: #fff8e1;
	--verde-futuro: #4caf50;
	--verde-whatsapp: #8bc34a;
	--verde-50: #dcedc8;
	--azul-ligacoes-50: #e1f5fe;
	--azul-ligacoes-500: #03a9f4;
	--roxo-presencial-50: #ede7f6;
	--roxo-presencial-400: #7e57c2;
	--laranja-email: #ff5722;
	--white: #ffffff;
	--blue-50: #e3f2fd;
	--Red_A400: #ff1744;
	--indigo-400: #3d5afe;
	--indigo-500: #304ffe;
	--border-cinza: #d9d9d9;
	--input-text: #6c757d;
	--check-filtro-tarefa-concluido: #f3e5f5;
	--check-filtro-tarefa-concluido-text: #673ab7;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

body {
	width: 100vw;
	height: 100vh;
	font-family: 'Open Sans', sans-serif;
}

.global-spinner-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000000;
	transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

label {
	color: var(--input-text) !important;
}

.container-happy {
	background-color: var(--cinza-azulado-50);
	display: flex;
	flex-direction: row;
	height: auto;
	min-height: 100vh;
	max-width: 100vw;
}

.cursor {
	cursor: pointer !important;
}

/*Flex*/
.d-none {
	display: none !important;
}

.flex {
	display: flex;
}

.flex-row {
	flex-direction: row;
}

.flex-column {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-fill {
	flex: 1;
}

.justify-start {
	justify-content: flex-start;
}

.justify-center {
	justify-content: center !important;
}

.justify-between {
	justify-content: space-between;
}

.justify-end {
	justify-content: flex-end;
}

.align-center {
	align-items: center;
}

.align-end {
	align-items: flex-end;
}

.gap-5 {
	gap: 5px;
}

.gap-10 {
	gap: 10px;
}

.gap-20 {
	gap: 20px;
}

/*width*/
.w-5 {
	width: 5%;
}
.w-10 {
	width: 10% !important;
}

.w-15 {
	width: 15%;
}

.w-20 {
	width: 20% !important;
}

.w-25 {
	width: 25%;
}

.w-30 {
	width: 30%;
}

.w-40 {
	width: 40%;
}

.w-50 {
	width: 50%;
}

.w-60 {
	width: 60%;
}

.w-70 {
	width: 70%;
}

.w-75 {
	width: 75%;
}

.w-100 {
	width: 100% !important;
}

/*Height*/

.h-10 {
	height: 10px !important;
}

.h-50 {
	height: 50px !important;
}

/* Margin */

.mb-0 {
	margin-bottom: 0px !important;
}

.mb-5 {
	margin-bottom: 5px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.ml-5 {
	margin-left: 5px !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.ml-20 {
	margin-left: 20px !important;
}

.mr-5 {
	margin-right: 5px !important;
}

.mr-10 {
	margin-right: 10px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.mr-30 {
	margin-right: 30px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.pb-50 {
	padding-bottom: 50px !important;
}

.pl-10 {
	padding-left: 10px !important;
}

.pl-20 {
	padding-left: 20px !important;
}

/*Background */

.b-blue {
	background-color: var(--azul-secundario) !important;
}

.b-red {
	background-color: var(--vermelho-50) !important;
}

.b-amarelo {
	background-color: var(--amarelo-50) !important;
}

.b-verde {
	background-color: var(--verde-50) !important;
}

/*Elements*/

.input-happy {
	border: 1px solid var(--cinza-azulado-900);
	padding: 5px 10px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	max-width: 100%;
	min-width: 100%;
	height: 50px;
	max-height: 55px;
}

.input-modal {
	border: 1px solid rgba(0, 0, 0, 0.12);
	padding: 5px 10px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: Roboto !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 24px !important;
	max-width: 100%;
	min-width: 100%;
	height: 40px;
	max-height: 45px;
}

.input-textarea {
	border: 1px solid rgba(0, 0, 0, 0.12);
	padding: 5px 10px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: Roboto !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 24px !important;
	max-width: 100%;
	min-width: 100%;
	height: auto;
	max-height: 300px;
}

.button-primary {
	background: var(--azul-happy) !important;
	border-radius: 4px !important;
	color: var(--white) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 50px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	cursor: pointer;
	transition: 0.8ms;
}

.button-primary:hover {
	background-color: var(--primary-blue-base) !important;
	transform: translateX(-2px);
}

.button-blue {
	background: var(--azul-secundario) !important;
	border-radius: 4px !important;
	color: var(--azul-happy) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 50px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	cursor: pointer;
	transition: 0.8ms;
}

.button-blue:hover {
	background-color: var(--primary-blue-base) !important;
	transform: translateX(-2px);
	color: var(--white) !important;
}

.button-bronce {
	background: var(--primary-blue-base) !important;
	border-radius: 4px !important;
	color: var(--white) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 50px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	cursor: pointer;
	transition: 1.8ms;
}

.button-bronce:hover {
	background-color: var(--azul-happy) !important;
	transform: translateX(-2px);
}

.button-duplicate {
	background: var(--cinza-azulado-50) !important;
	border-radius: 4px !important;
	color: var(--cinza-azulado-400) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 45px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	margin-left: 10px !important;
	cursor: pointer;
	transition: 0.8ms;
}

.button-descartar {
	background: var(--vermelho-50) !important;
	border-radius: 4px !important;
	color: var(--vermelho-atrasado) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 45px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	margin-left: 10px !important;
	cursor: pointer;
	transition: 0.8ms;
}

.button-venda {
	background: var(--verde-50) !important;
	border-radius: 4px !important;
	color: var(--verde-futuro) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 45px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	margin-left: 10px !important;
	cursor: pointer;
	transition: 0.8ms;
}

.button-verde {
	background: var(--verde-whatsapp) !important;
	border-radius: 4px !important;
	color: var(--white) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 50px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	margin-left: 10px !important;
	cursor: pointer;
	transition: 0.8ms;
}

.button-verde:hover {
	background-color: var(--verde-futuro) !important;
	transform: translateX(-2px);
}

.button-red {
	background: var(--vermelho-atrasado) !important;
	border-radius: 4px !important;
	color: var(--white) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 50px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	cursor: pointer;
	transition: 0.8ms;
}

.button-red:hover {
	background: #ff4d4f !important;
	transform: translateX(-2px);
}

.button-white {
	background: transparent !important;
	border-radius: 4px !important;
	color: var(--white) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 45px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 2px solid var(--white) !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	cursor: pointer;
	transition: 0.8ms;

	span {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 24px;
		color: var(--white) !important;
	}
}

.button-line {
	background: var(--white) !important;
	border-radius: 4px !important;
	color: var(--azul-happy) !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center !important;
	height: 50px !important;
	max-width: 100% !important;
	min-width: 150px !important;
	width: auto;
	border: 1px solid !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 25px 20px;
	cursor: pointer;
	transition: 1.8ms;
}

.button-line:hover {
	background: var(--azul-happy) !important;
	color: var(--white) !important;
	transform: translateX(2px);

	span sgv {
		color: var(--white) !important;
	}
}

/* Antd */

.ant-input {
	color: var(--input-text) !important;
}

.ant-input-number-input {
	color: var(--input-text) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s !important;
	-webkit-text-fill-color: #344453 !important;
}

input:focus {
	outline-offset: 0px !important;
	outline: none !important;
}

::placeholder {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: rgba(52, 68, 83, 0.5);
}

.ant-select-selection-item {
	color: var(--input-text);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: none !important;
	background-color: transparent !important;
}

.ant-pagination-item {
	border: 0px solid var(--azul-happy) !important;
	border-radius: 16px !important;
	background-color: transparent !important;

	a {
		padding: 0px !important;
		margin-left: 0px !important;
		font-size: 14px !important;
		line-height: 30px !important;
		color: var(--azul-happy) !important;
	}
}

.ant-pagination-item-active {
	border: 1px solid var(--azul-happy) !important;
	border-radius: 16px !important;

	a {
		color: var(--azul-happy) !important;
	}
}

.ant-pagination-prev {
	.ant-pagination-item-link {
		border: 1px solid var(--azul-happy) !important;
		border-radius: 16px !important;
		background-color: var(--azul-happy) !important;
		color: var(--white);
	}
}

.ant-pagination-disabled {
	.ant-pagination-item-link {
		color: var(--white) !important;
	}
}

.ant-pagination-next {
	.ant-pagination-item-link {
		border: 1px solid var(--azul-happy) !important;
		border-radius: 16px !important;
		background-color: var(--azul-happy) !important;
		color: var(--white);
	}
}

.ant-input:focus,
.input-modal:focus,
.input-textarea:focus {
	border-right-width: 0px !important;
	outline: none !important;
	outline-offset: 0px !important;
	border-color: var(--input-text) !important;
	box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}

.ant-input:hover,
.input-modal:hover,
.input-textarea:hover {
	border-color: var(--input-text) !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	background-color: var(--azul-secundario) !important;

	span {
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--azul-happy);
	}
}

.modal-leads-atrasado .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	background-color: var(--vermelho-atrasado) !important;

	span {
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--white);
	}
}

.modal-leads-hoje .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	background-color: var(--amarelo-hoje) !important;

	span {
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--white);
	}
}

.modal-leads-futuro .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	background-color: var(--verde-futuro) !important;

	span {
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--white);
	}
}

#text-note {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--cinza-azulado-400);
}

.text-cinza {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.4px;
	color: var(--cinza-azulado-300);
}

.text-blue {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.4px;
	color: var(--azul-happy);
}

.ant-radio-inner::after {
	background-color: var(--azul-happy) !important;
}

.ant-radio-checked .ant-radio-inner {
	border-color: var(--azul-happy) !important;
}

.css-1ewi0lz-MuiButtonBase-root-MuiCheckbox-root,
.css-hyniuq-MuiButtonBase-root-MuiCheckbox-root,
.css-1pkkaw7-MuiButtonBase-root-MuiCheckbox-root,
.css-1ku2km8-MuiButtonBase-root-MuiCheckbox-root,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root,
.css-6sj1q1-MuiButtonBase-root-MuiCheckbox-root {
	padding: 0px !important;
}

.note-box {
	position: relative;
	padding-top: 7px;

	.dot-red {
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background-color: var(--Red_A400);
		margin-top: -30px;
		margin-left: 23px;
		position: absolute;
	}
}

.circle-cinza {
	background-color: var(--cinza-azulado-100);
	border-radius: 20px;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
	margin-left: 15px;
}

.ant-radio-group .ant-radio-group-outline {
	display: flex !important;
	gap: 20px !important;
	flex-wrap: wrap !important;
}

.disable-scrollbars::-webkit-scrollbar {
	background: transparent; /* Chrome/Safari/Webkit */
	width: 0px;
}

.disable-scrollbars {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
}

.ant-form-item .ant-form-item-control-input-content {
	color: var(--input-text);
}
