.modal-render-html img {
	width: 100%;
}

.modal-button-html {
	color: #fff;
	background-color: var(--primary-blue-base) !important;
	border-color: transparent;
	margin-bottom: 5px;
}

.modal-button-html:hover {
	color: #fff !important;
	border-color: transparent !important;
	background-color: var(--azul-happy) !important;
	transform: translateX(-2px);
}

.modal-button-html-email {
	color: #fff;
	background-color: var(--primary-blue-base) !important;
	border-color: transparent;
	margin-bottom: 5px;
}

.modal-button-html-email:hover {
	color: #fff !important;
	border-color: transparent !important;
	background-color: var(--azul-happy) !important;
	transform: translateX(-2px);
}

.modal-button-html-whatsapp {
	color: #fff;
	background-color: var(--verde-whatsapp) !important;
	border-color: transparent;
	margin-bottom: 5px;
}

.modal-button-html-whatsapp:hover {
	color: #fff !important;
	border-color: transparent !important;
	background-color: var(--verde-futuro) !important;
	transform: translateX(-2px);
}
