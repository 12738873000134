.modal-container {
	width: 100vw;
	height: 100vh;
	background-color: #00000096;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 100;
	margin: 0px;

	.modal-successo-leads{
		padding: 30px 30px;
		position: relative;
		width: 700px;
		min-height: 250px;
		height: auto;
		max-height: 650px;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;

		.button-x {
			position: absolute;
			top: 10px;
			right: 15px;
			background: transparent !important;
			border-radius: 4px !important;
			color: var(--cinza-azulado) !important;
			font-family: Open Sans !important;
			font-style: normal !important;
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 24px !important;
			text-align: center !important;
			height: 40px !important;
			max-width: 100% !important;
			width: auto;
			border: 0px !important;
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 25px 20px;
			cursor: pointer;
		}
	}

	.modal {
		padding: 30px 30px;
		position: relative;
		width: 700px;
		min-height: 250px;
		height: auto;
		max-height: 650px;
		overflow-y: scroll;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;

		.button-x {
			position: absolute;
			top: 10px;
			right: 15px;
			background: transparent !important;
			border-radius: 4px !important;
			color: var(--cinza-azulado) !important;
			font-family: Open Sans !important;
			font-style: normal !important;
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 24px !important;
			text-align: center !important;
			height: 40px !important;
			max-width: 100% !important;
			width: auto;
			border: 0px !important;
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 25px 20px;
			cursor: pointer;
		}

		h3 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 24px;
			color: #000000;
			margin-bottom: 15px !important;
		}

		small {
			margin-top: -20px;
			margin-bottom: 10px;
			color: var(--vermelho-atrasado);
		}

		.text-over-input {
			position: relative;
			top: 10px;
			left: 20px;
			background-color: var(--white);
			width: fit-content;
			padding: 0x 10px;
			text-align: center;

			label {
				font-size: 14px;
				text-transform: uppercase;
				color: var(--cinza-azulado-900);
			}

			label:hover {
				color: #6200ee;
			}
		}

		h4 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: rgba(0, 0, 0, 0.87);
		}

		h6 {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.checkboxes {
			padding: 15px;
			background-color: var(--azul-secundario);
			border-radius: 10px;
			margin-bottom: 15px;

			.checkbox {
				margin-bottom: 15px;

				p {
					font-family: Open Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--cinza-azulado-400);
					margin-left: 15px;
				}

				.ant-checkbox-checked::after {
					border: 1px solid var(--azul-happy) !important;
				}

				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: var(--azul-happy) !important;
					border-color: var(--azul-happy) !important;
				}
			}
		}

		.ant-switch {
			background-color: rgba(187, 134, 252, 0.38) !important;
		}

		.ant-switch-checked {
			background-color: #6200ee !important;
		}

		h5 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.icons {
			.starts {
				input[type='checkbox'] {
					display: none;
				}

				label {
					svg {
						margin-left: 5px;
					}
				}
			}
		}
	}

	.modal-resposta {
		padding: 30px 30px;
		position: relative;
		width: 375px;
		min-height: 100px;
		height: auto;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;

		h3 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: rgba(0, 0, 0, 0.87);
			margin-bottom: 20px !important;
		}

		p {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.25px;
			color: rgba(0, 0, 0, 0.6);
			margin-bottom: 20px !important;
		}
	}

	.modal-leads {
		padding: 30px 30px;
		position: relative;
		width: 1200px;
		min-height: 615px;
		height: auto;
		max-height: 615px;
		overflow-y: scroll;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;
		border-bottom: 5px solid var(--azul-happy);

		.button-x {
			position: absolute;
			top: 10px;
			right: 15px;
			background: transparent !important;
			border-radius: 4px !important;
			color: var(--cinza-azulado) !important;
			font-family: Open Sans !important;
			font-style: normal !important;
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 24px !important;
			text-align: center !important;
			height: 40px !important;
			max-width: 100% !important;
			width: auto;
			border: 0px !important;
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 25px 20px;
			cursor: pointer;
		}

		h3 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 24px;
			color: #000000;
		}

		label {
			margin-top: -20px;
			margin-bottom: 10px;
			color: var(--vermelho-atrasado);
		}

		.text-over-input {
			position: relative;
			top: 10px;
			left: 20px;
			background-color: var(--white);
			width: fit-content;
			padding: 0x 10px;
			text-align: center;

			label {
				color: var(--cinza-azulado-900);
			}

			label:hover {
				color: #6200ee;
			}
		}

		h4 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: rgba(0, 0, 0, 0.87);
		}

		h6 {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.checkboxes {
			padding: 15px;
			background-color: var(--azul-secundario);
			border-radius: 10px;
			margin-bottom: 15px;

			.checkbox {
				margin-bottom: 15px;

				p {
					font-family: Open Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--cinza-azulado-400);
					margin-left: 15px;
				}

				.ant-checkbox-checked::after {
					border: 1px solid var(--azul-happy) !important;
				}

				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: var(--azul-happy) !important;
					border-color: var(--azul-happy) !important;
				}
			}
		}

		.ant-switch {
			background-color: rgba(187, 134, 252, 0.38) !important;
		}

		.ant-switch-checked {
			background-color: #6200ee !important;
		}

		h5 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.icons {
			.starts {
				input[type='checkbox'] {
					display: none;
				}

				label {
					svg {
						margin-left: 5px;
					}
				}
			}
		}

		.box-collapse {
			max-height: 250px;
			overflow-y: scroll;
		}

		.box-historico {
			.header-historico {
				background-color: var(--azul-secundario);
				border-radius: 0px;
				padding: 13px 20px;

				span {
					font-family: Open Sans;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--azul-happy);
				}
			}

			.body-historico {
				max-height: 370px;
				overflow-y: scroll;

				.line-vertical {
					width: 50px;
					height: auto;
					background-color: transparent;
					padding-top: 15px;

					.line {
						width: 4px;
						height: 100%;
						background-color: var(--cinza-azulado-50);
					}
				}

				.leads-vertical {
					.lead-historico {
						width: 100%;
						height: 72px;
						max-height: 75px;
						background-color: var(--white);
						box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
						border-radius: 8px;
						margin-top: 10px;
						padding: 15px;
						position: relative;

						.dot {
							height: 10px;
							width: 10px;
							border-radius: 5px;
							background-color: var(--azul-happy);
							position: absolute;
							left: -29px;
						}

						.box-name {
							border-right: 1px solid var(--cinza-azulado-400);
							padding-right: 20px;
							margin-right: 20px;
						}

						.box-status {
							span {
								font-family: Roboto;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								letter-spacing: 0.4px;
								color: var(--cinza-azulado-900);
								margin-left: 10px;
							}

							label {
								font-family: Roboto;
								font-style: normal;
								font-weight: normal;
								font-size: 7px;
								line-height: 10px;
								letter-spacing: 0.4px;
								color: var(--cinza-azulado-500);
								margin: 0px !important;
								max-width: 90%;
							}
						}
					}
				}
			}
		}

		.status {
			background-color: var(--azul-happy);
			color: var(--white);
			padding: 5px 10px;
			margin-right: 10px;
		}

		.starts {
			border-right: 2px solid var(--cinza-azulado);
			padding-right: 10px;
			margin-right: 10px;
		}
	}

	.modal-leads-atrasado {
		padding: 30px 30px;
		position: relative;
		width: 1200px;
		min-height: 615px;
		height: auto;
		max-height: 615px;
		overflow-y: scroll;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;
		border-bottom: 5px solid var(--vermelho-atrasado);

		.button-x {
			position: absolute;
			top: 10px;
			right: 15px;
			background: transparent !important;
			border-radius: 4px !important;
			color: var(--cinza-azulado) !important;
			font-family: Open Sans !important;
			font-style: normal !important;
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 24px !important;
			text-align: center !important;
			height: 40px !important;
			max-width: 100% !important;
			width: auto;
			border: 0px !important;
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 25px 20px;
			cursor: pointer;
		}

		h3 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 24px;
			color: #000000;
		}

		label {
			margin-top: -20px;
			margin-bottom: 10px;
			color: var(--vermelho-atrasado);
		}

		.text-over-input {
			position: relative;
			top: 10px;
			left: 20px;
			background-color: var(--white);
			width: fit-content;
			padding: 0x 10px;
			text-align: center;

			label {
				color: var(--cinza-azulado-900);
			}

			label:hover {
				color: #6200ee;
			}
		}

		h4 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: rgba(0, 0, 0, 0.87);
		}

		h6 {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.checkboxes {
			padding: 15px;
			background-color: var(--azul-secundario);
			border-radius: 10px;
			margin-bottom: 15px;

			.checkbox {
				margin-bottom: 15px;

				p {
					font-family: Open Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--cinza-azulado-400);
					margin-left: 15px;
				}

				.ant-checkbox-checked::after {
					border: 1px solid var(--azul-happy) !important;
				}

				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: var(--azul-happy) !important;
					border-color: var(--azul-happy) !important;
				}
			}
		}

		.ant-switch {
			background-color: rgba(187, 134, 252, 0.38) !important;
		}

		.ant-switch-checked {
			background-color: #6200ee !important;
		}

		h5 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.icons {
			.starts {
				input[type='checkbox'] {
					display: none;
				}

				label {
					svg {
						margin-left: 5px;
					}
				}
			}
		}

		.box-collapse {
			max-height: 250px;
			overflow-y: scroll;
		}

		.box-historico {
			.header-historico {
				background-color: var(--vermelho-atrasado);
				border-radius: 0px;
				padding: 13px 20px;

				span {
					font-family: Open Sans;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--white);
				}
			}

			.body-historico {
				max-height: 370px;
				overflow-y: scroll;

				.line-vertical {
					width: 50px;
					height: auto;
					background-color: transparent;
					padding-top: 15px;

					.line {
						width: 4px;
						height: 100%;
						background-color: var(--cinza-azulado-50);
					}
				}

				.leads-vertical {
					.lead-historico {
						width: 100%;
						height: 72px;
						max-height: 75px;
						background-color: var(--white);
						box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
						border-radius: 8px;
						margin-top: 10px;
						padding: 15px;
						position: relative;

						.dot {
							height: 10px;
							width: 10px;
							border-radius: 5px;
							background-color: var(--vermelho-atrasado);
							position: absolute;
							left: -29px;
						}

						.box-name {
							border-right: 1px solid var(--cinza-azulado-400);
							padding-right: 20px;
							margin-right: 20px;
						}

						.box-status {
							span {
								font-family: Roboto;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								letter-spacing: 0.4px;
								color: var(--cinza-azulado-900);
								margin-left: 10px;
							}

							label {
								font-family: Roboto;
								font-style: normal;
								font-weight: normal;
								font-size: 7px;
								line-height: 10px;
								letter-spacing: 0.4px;
								color: var(--cinza-azulado-500);
								margin: 0px !important;
								max-width: 90%;
							}
						}
					}
				}
			}
		}

		.status {
			background-color: var(--vermelho-atrasado);
			color: var(--white);
			padding: 5px 10px;
			margin-right: 10px;
		}

		.starts {
			border-right: 2px solid var(--cinza-azulado);
			padding-right: 10px;
			margin-right: 10px;
		}
	}

	.modal-planilha-leads {
		padding: 30px 30px;
		position: relative;
		width: 1000px;
		min-height: 250px;
		height: auto;
		max-height: 650px;
		overflow-y: scroll;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;

		.formWrapper {
			border: 1px solid rgba(105, 105, 105, 0.15);
			display: inline-block;
			border-radius: 8px;
			cursor: pointer;
		}

		.formWrapper:hover {
			border: 1px solid #1a4da1;
		}

		div.upload {
			width: 200px;
			height: 40px;
			overflow: hidden;
			position: relative;
			float: left;
		}
		
		div.upload input {
			display: block !important;
			width: 200px !important;
			height: 40px !important;
			opacity: 0 !important;
			overflow: hidden !important;
			cursor: pointer;
		}
		.upload input[type="button"], .upload input[type="submit"], .upload input[type="reset"], .upload input[type="file"]::-webkit-file-upload-button, .upload button {
			cursor: pointer;
		}
		
		.inputFileOverlay {
			position: absolute;
			width: 100%;
			font-family: sans-serif;
			line-height: 42px;
			text-align: center;
		}
		
		.formWrapper input.enviar {
			float: left;
			height: 40px;
			font-family: sans-serif;
			font-weight: bold;
			font-size: 15px;
			border: 0;
			padding: 0 17px;
			cursor: pointer;
		}

		.button-x {
			position: absolute;
			top: 10px;
			right: 15px;
			background: transparent !important;
			border-radius: 4px !important;
			color: var(--cinza-azulado) !important;
			font-family: Open Sans !important;
			font-style: normal !important;
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 24px !important;
			text-align: center !important;
			height: 40px !important;
			max-width: 100% !important;
			width: auto;
			border: 0px !important;
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 25px 20px;
			cursor: pointer;
		}

		h3 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 24px;
			color: #000000;
			margin-bottom: 15px !important;
		}

		small {
			margin-top: -20px;
			margin-bottom: 10px;
			color: var(--vermelho-atrasado);
		}

		.text-over-input {
			position: relative;
			top: 10px;
			left: 20px;
			background-color: var(--white);
			width: fit-content;
			padding: 0x 10px;
			text-align: center;

			label {
				font-size: 14px;
				text-transform: uppercase;
				color: var(--cinza-azulado-900);
			}

			label:hover {
				color: #6200ee;
			}
		}

		h4 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: rgba(0, 0, 0, 0.87);
		}

		h6 {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}


		.ant-switch {
			background-color: rgba(187, 134, 252, 0.38) !important;
		}

		.ant-switch-checked {
			background-color: #6200ee !important;
		}

		h5 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.icons {
			.starts {
				input[type='checkbox'] {
					display: none;
				}

				label {
					svg {
						margin-left: 5px;
					}
				}
			}
		}
	}

	.modal-resposta {
		padding: 30px 30px;
		position: relative;
		width: 375px;
		min-height: 100px;
		height: auto;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;

		h3 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: rgba(0, 0, 0, 0.87);
			margin-bottom: 20px !important;
		}

		p {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.25px;
			color: rgba(0, 0, 0, 0.6);
			margin-bottom: 20px !important;
		}
	}

	.modal-leads-hoje {
		padding: 30px 30px;
		position: relative;
		width: 1200px;
		min-height: 615px;
		height: auto;
		max-height: 615px;
		overflow-y: scroll;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;
		border-bottom: 5px solid var(--amarelo-hoje);

		.button-x {
			position: absolute;
			top: 10px;
			right: 15px;
			background: transparent !important;
			border-radius: 4px !important;
			color: var(--cinza-azulado) !important;
			font-family: Open Sans !important;
			font-style: normal !important;
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 24px !important;
			text-align: center !important;
			height: 40px !important;
			max-width: 100% !important;
			width: auto;
			border: 0px !important;
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 25px 20px;
			cursor: pointer;
		}

		h3 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 24px;
			color: #000000;
		}

		label {
			margin-top: -20px;
			margin-bottom: 10px;
			color: var(--vermelho-atrasado);
		}

		.text-over-input {
			position: relative;
			top: 10px;
			left: 20px;
			background-color: var(--white);
			width: fit-content;
			padding: 0x 10px;
			text-align: center;

			label {
				color: var(--cinza-azulado-900);
			}

			label:hover {
				color: #6200ee;
			}
		}

		h4 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: rgba(0, 0, 0, 0.87);
		}

		h6 {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.checkboxes {
			padding: 15px;
			background-color: var(--azul-secundario);
			border-radius: 10px;
			margin-bottom: 15px;

			.checkbox {
				margin-bottom: 15px;

				p {
					font-family: Open Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--cinza-azulado-400);
					margin-left: 15px;
				}

				.ant-checkbox-checked::after {
					border: 1px solid var(--azul-happy) !important;
				}

				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: var(--azul-happy) !important;
					border-color: var(--azul-happy) !important;
				}
			}
		}

		.ant-switch {
			background-color: rgba(187, 134, 252, 0.38) !important;
		}

		.ant-switch-checked {
			background-color: #6200ee !important;
		}

		h5 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.icons {
			.starts {
				input[type='checkbox'] {
					display: none;
				}

				label {
					svg {
						margin-left: 5px;
					}
				}
			}
		}

		.box-collapse {
			max-height: 250px;
			overflow-y: scroll;
		}

		.box-historico {
			.header-historico {
				background-color: var(--amarelo-hoje);
				border-radius: 0px;
				padding: 13px 20px;

				span {
					font-family: Open Sans;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--white);
				}
			}

			.body-historico {
				max-height: 370px;
				overflow-y: scroll;

				.line-vertical {
					width: 50px;
					height: auto;
					background-color: transparent;
					padding-top: 15px;

					.line {
						width: 4px;
						height: 100%;
						background-color: var(--cinza-azulado-50);
					}
				}

				.leads-vertical {
					.lead-historico {
						width: 100%;
						height: 72px;
						max-height: 75px;
						background-color: var(--white);
						box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
						border-radius: 8px;
						margin-top: 10px;
						padding: 15px;
						position: relative;

						.dot {
							height: 10px;
							width: 10px;
							border-radius: 5px;
							background-color: var(--amarelo-hoje);
							position: absolute;
							left: -29px;
						}

						.box-name {
							border-right: 1px solid var(--cinza-azulado-400);
							padding-right: 20px;
							margin-right: 20px;
						}

						.box-status {
							span {
								font-family: Roboto;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								letter-spacing: 0.4px;
								color: var(--cinza-azulado-900);
								margin-left: 10px;
							}

							label {
								font-family: Roboto;
								font-style: normal;
								font-weight: normal;
								font-size: 7px;
								line-height: 10px;
								letter-spacing: 0.4px;
								color: var(--cinza-azulado-500);
								margin: 0px !important;
								max-width: 90%;
							}
						}
					}
				}
			}
		}

		.status {
			background-color: var(--amarelo-hoje);
			color: var(--white);
			padding: 5px 10px;
			margin-right: 10px;
		}

		.starts {
			border-right: 2px solid var(--cinza-azulado);
			padding-right: 10px;
			margin-right: 10px;
		}
	}

	.modal-leads-futuro {
		padding: 30px 30px;
		position: relative;
		width: 1200px;
		min-height: 615px;
		height: auto;
		max-height: 615px;
		overflow-y: scroll;
		background-color: var(--white);
		box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
		border-radius: 8px;
		border-bottom: 5px solid var(--verde-futuro);

		.button-x {
			position: absolute;
			top: 10px;
			right: 15px;
			background: transparent !important;
			border-radius: 4px !important;
			color: var(--cinza-azulado) !important;
			font-family: Open Sans !important;
			font-style: normal !important;
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 24px !important;
			text-align: center !important;
			height: 40px !important;
			max-width: 100% !important;
			width: auto;
			border: 0px !important;
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 25px 20px;
			cursor: pointer;
		}

		h3 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 24px;
			color: #000000;
		}

		label {
			margin-top: -20px;
			margin-bottom: 10px;
			color: var(--vermelho-atrasado);
		}

		.text-over-input {
			position: relative;
			top: 10px;
			left: 20px;
			background-color: var(--white);
			width: fit-content;
			padding: 0x 10px;
			text-align: center;

			label {
				color: var(--cinza-azulado-900);
			}

			label:hover {
				color: #6200ee;
			}
		}

		h4 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: rgba(0, 0, 0, 0.87);
		}

		h6 {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.checkboxes {
			padding: 15px;
			background-color: var(--azul-secundario);
			border-radius: 10px;
			margin-bottom: 15px;

			.checkbox {
				margin-bottom: 15px;

				p {
					font-family: Open Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--cinza-azulado-400);
					margin-left: 15px;
				}

				.ant-checkbox-checked::after {
					border: 1px solid var(--azul-happy) !important;
				}

				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: var(--azul-happy) !important;
					border-color: var(--azul-happy) !important;
				}
			}
		}

		.ant-switch {
			background-color: rgba(187, 134, 252, 0.38) !important;
		}

		.ant-switch-checked {
			background-color: #6200ee !important;
		}

		h5 {
			font-family: Open Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: var(--cinza-azulado-400);
		}

		.icons {
			.starts {
				input[type='checkbox'] {
					display: none;
				}

				label {
					svg {
						margin-left: 5px;
					}
				}
			}
		}

		.box-collapse {
			max-height: 250px;
			overflow-y: scroll;
		}

		.box-historico {
			.header-historico {
				background-color: var(--verde-futuro);
				border-radius: 0px;
				padding: 13px 20px;

				span {
					font-family: Open Sans;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.15px;
					color: var(--white);
				}
			}

			.body-historico {
				max-height: 370px;
				overflow-y: scroll;

				.line-vertical {
					width: 50px;
					height: auto;
					background-color: transparent;
					padding-top: 15px;

					.line {
						width: 4px;
						height: 100%;
						background-color: var(--cinza-azulado-50);
					}
				}

				.leads-vertical {
					.lead-historico {
						width: 100%;
						height: 72px;
						max-height: 75px;
						background-color: var(--white);
						box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
						border-radius: 8px;
						margin-top: 10px;
						padding: 15px;
						position: relative;

						.dot {
							height: 10px;
							width: 10px;
							border-radius: 5px;
							background-color: var(--verde-futuro);
							position: absolute;
							left: -29px;
						}

						.box-name {
							border-right: 1px solid var(--cinza-azulado-400);
							padding-right: 20px;
							margin-right: 20px;
						}

						.box-status {
							span {
								font-family: Roboto;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								letter-spacing: 0.4px;
								color: var(--cinza-azulado-900);
								margin-left: 10px;
							}

							label {
								font-family: Roboto;
								font-style: normal;
								font-weight: normal;
								font-size: 7px;
								line-height: 10px;
								letter-spacing: 0.4px;
								color: var(--cinza-azulado-500);
								margin: 0px !important;
								max-width: 90%;
							}
						}
					}
				}
			}
		}

		.status {
			background-color: var(--verde-futuro);
			color: var(--white);
			padding: 5px 10px;
			margin-right: 10px;
		}

		.starts {
			border-right: 2px solid var(--cinza-azulado);
			padding-right: 10px;
			margin-right: 10px;
		}
	}
}

.line {
	border: 0.5px solid #cfd8dc;
	// border: 0.5px solid #0c306c;
	width: 100%;

	h3 {
		color: #cfd8dc !important;
	}
}