.tag{
  background-color: var(--azul-secundario);
  border-radius: 16px;
  width: fit-content;
  padding: 8px 16px;
  margin-right: 10px;
  margin-bottom: 10px;

  span{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color:var(--cinza-azulado-900);
  }
}

.tag-whatsapp{
  background-color: var(--verde-50);
  border-radius: 16px;
  width: fit-content;
  padding: 8px 16px;
  margin-right: 10px;
  margin-bottom: 10px;

  span{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 24px;
    letter-spacing: 0.15px;
    color:var(--verde-futuro) !important;
  }
}

.tag-presencial{
  background-color: var(--roxo-presencial-50);
  border-radius: 16px;
  width: fit-content;
  padding: 8px 16px;
  margin-right: 10px;
  margin-bottom: 10px;

  span{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 24px;
    letter-spacing: 0.15px;
    color:var(--roxo-presencial-400) !important;
  }
}

.tag-email{
  background-color: var(--vermelho-50);
  border-radius: 16px;
  width: fit-content;
  padding: 8px 16px;
  margin-right: 10px;
  margin-bottom: 10px;

  span{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 24px;
    letter-spacing: 0.15px;
    color:var(--vermelho-atrasado) !important;
  }
}

.tag-phone{
  background-color: var(--azul-ligacoes-50);
  border-radius: 16px;
  width: fit-content;
  padding: 8px 16px;
  margin-right: 10px;
  margin-bottom: 10px;

  span{
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 24px;
    letter-spacing: 0.15px;
    color:var(--azul-ligacoes-500) !important;
  }
}
