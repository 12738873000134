.square{
  width: 32%;
  height: auto;
  max-height: 135px;
  background: var(--white);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;

  .icon{
    height: 40px;
    width: 40px;
    min-width: 40px !important;
    border-radius: 20px;
    background-color: var(--azul-happy);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span{
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px;
    color: var(--azul-happy) !important;
    margin-left: 10px;
  }

  .line{
    background: #C4C4C4;
    border: 1.5px solid var(--cinza-azulado-100);
    margin: 15px -16px 15px -16px
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-500);
  }
}

.square-roxo{
  width: 32%;
  height: auto;
  max-height: 135px;
  background: var(--white);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;

  .icon{
    height: 40px;
    width: 40px;
    min-width: 40px !important;
    border-radius: 20px;
    background-color: var(--roxo-presencial-400);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span{
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px;
    color: var(--roxo-presencial-400) !important;
    margin-left: 10px;
  }

  .line{
    background: #C4C4C4;
    border: 1.5px solid var(--cinza-azulado-100);
    margin: 15px -16px 15px -16px
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-500);
  }
}

.square-vermelho{
  width: 32%;
  height: auto;
  max-height: 135px;
  background: var(--white);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;

  .icon{
    height: 40px;
    width: 40px;
    min-width: 40px !important;
    border-radius: 20px;
    background-color: var(--vermelho-atrasado);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span{
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px;
    color: var(--vermelho-atrasado) !important;
    margin-left: 10px;
  }

  .line{
    background: #C4C4C4;
    border: 1.5px solid var(--cinza-azulado-100);
    margin: 15px -16px 15px -16px
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-500);
  }
}

.square-verde{
  width: 32%;
  height: auto;
  max-height: 135px;
  background: var(--white);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;

  .icon{
    height: 40px;
    width: 40px;
    min-width: 40px !important;
    border-radius: 20px;
    background-color: var(--verde-futuro);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span{
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px;
    color: var(--verde-futuro) !important;
    margin-left: 10px;
  }

  .line{
    background: #C4C4C4;
    border: 1.5px solid var(--cinza-azulado-100);
    margin: 15px -16px 15px -16px
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-500);
  }
}

.square-azul{
  width: 32%;
  height: auto;
  max-height: 135px;
  background: var(--white);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;

  .icon{
    height: 40px;
    width: 40px;
    min-width: 40px !important;
    border-radius: 20px;
    background-color: var(--azul-ligacoes-500);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span{
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px;
    color: var(--azul-ligacoes-500) !important;
    margin-left: 10px;
  }

  .line{
    background: #C4C4C4;
    border: 1.5px solid var(--cinza-azulado-100);
    margin: 15px -16px 15px -16px
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-500);
  }
}

.square-amarelo{
  width: 32%;
  height: auto;
  max-height: 135px;
  background: var(--white);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;

  .icon{
    height: 40px;
    width: 40px;
    min-width: 40px !important;
    border-radius: 20px;
    background-color: var(--amarelo-hoje);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span{
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px;
    color: var(--amarelo-hoje) !important;
    margin-left: 10px;
  }

  .line{
    background: #C4C4C4;
    border: 1.5px solid var(--cinza-azulado-100);
    margin: 15px -16px 15px -16px
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-500);
  }
}

