.row-item{
  height: 70px;
  min-height: 70px;
  border-bottom: 2px solid  #D9D5EC;

  .item{
    height: 100%;

    .ant-switch{
      background-color: rgba(187, 134, 252, 0.38) !important;
    }

    .ant-switch-checked {
      background-color: #6200EE !important;
    }

    p{
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color:var(--cinza-azulado-900);
    }

    .circle-cinza{
      background-color: var(--cinza-azulado-100);
      border-radius: 20px;
      height: 40px;
      width: 40px;
    }
  }
}

.row-item:hover{
  border-bottom: 2px solid  #6200EE;
  background-color: var(--azul-secundario);
}

.row-item-block{
  height: 70px;
  min-height: 70px;
  border-bottom: 2px solid  var(--vermelho-atrasado);
  background-color: var(--vermelho-50);

  .item{
    height: 100%;

    span{
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color:var(--cinza-azulado-900);
    }

    .circle-cinza{
      background-color: var(--cinza-azulado-100);
      border-radius: 20px;
      height: 40px;
      width: 40px;
    }
  }
}

.row-item:hover{
  border-bottom: 2px solid  #6200EE;
  background-color: var(--azul-secundario);
}

.row-item-select{
  height: 70px;
  min-height: 70px;
  border-bottom: 2px solid  #D9D5EC;
  background-color: var(--indigo-400);

  .item{
    height: 100%;

    .ant-switch{
      background-color: rgba(187, 134, 252, 0.38) !important;
    }

    .ant-switch-checked {
      background-color: #6200EE !important;
    }

    p{
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color:var(--white) !important;
    }

    .circle-cinza{
      background-color: var(--cinza-azulado-100);
      border-radius: 20px;
      height: 40px;
      width: 40px;
    }
  }
}

.row-item:hover{
  border-bottom: 2px solid  #6200EE;
  background-color: var(--azul-secundario);
}

.row-item-block{
  height: 70px;
  min-height: 70px;
  border-bottom: 2px solid  var(--vermelho-atrasado);
  background-color: var(--vermelho-50);

  .item{
    height: 100%;

    span{
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color:var(--cinza-azulado-900);
    }

    .circle-cinza{
      background-color: var(--cinza-azulado-100);
      border-radius: 20px;
      height: 40px;
      width: 40px;
    }
  }
}

.modal-note{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  border-radius: 8px;
  background: var(--white);
  padding: 25px;
  max-width: 360px;
  transition: 200ms;

  .button-close{
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: var(--cinza-azulado-500);
  }

  h3{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 20px !important;
  }

  h5{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #607D8B;
    margin-bottom: 20px !important;
  }

  h5#title-assunto{
    margin-bottom: 5px !important;
  }

  h6{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #607D8B;
    margin-bottom: 20px !important;
  }

  textarea{
    border-radius: 8px;
    border: 1px solid var(--cinza-azulado-400);
    padding: 10px;
    width:100%;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #6C757D;
  }

  p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6C757D;
    margin-bottom: 10px !important;
  }
}
