.tarefa-panel-futuro {
  width: 100%;
  border-radius: 8px;
  background-color: var(--verde-futuro);
  padding: 10px;
  margin-bottom: 15px;
  position: relative;

  h5{
    color:var(--white);
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  p{
    color:var(--white);
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
}

.tarefa-panel-novo {
  width: 100%;
  border-radius: 8px;
  background-color: var(--azul-happy);
  padding: 10px;
  margin-bottom: 15px;
  position: relative;

  h5{
    color:var(--white);
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  p{
    color:var(--white);
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
}

.tarefa-panel-hoje{
  width: 100%;
  border-radius: 8px;
  background-color: var(--amarelo-hoje);
  padding: 10px;
  margin-bottom: 15px;
  position: relative;

  h5{
    color:var(--white);
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  p{
    color:var(--white);
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
}


.tarefa-panel-atrasado{
  width: 100%;
  border-radius: 8px;
  background-color: var(--vermelho-atrasado);
  padding: 10px;
  margin-bottom: 15px;
  position: relative;

  h5{
    color:var(--white);
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  p{
    color:var(--white);
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
}
